<template>
    <div>
        <v-form id="form_promenada_application" ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card id="form_card">
                        <v-img
                        :src="imgPromenada"
                        height="300px"
                        ></v-img>
                        <v-card-title>
                            <span class="subtitle-1 application--card--title text-center">
                            <v-icon left>mdi-file-document-edit-outline</v-icon>
                            <v-chip label :color="application_media_type" dark >E-vloga</v-chip> za izdajo dovolilnice za dostop na blejsko promenado št. #{{ applicationId }}</span>
                        <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('card1')">
                                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">
                            <p style="" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon>Polja označena z * so vezana na podatke o uporaniku.</p>
                            
                            <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                            <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>


                            <!-- imetnik -->
                            <v-text-field
                                v-model="name"
                                label="Ime in priimek*"
                                required
                                :rules="name_rules"
                                filled
                            ></v-text-field>

                            <!-- ulica -->
                            <v-text-field
                                v-model="address"
                                label="Naslov (prebivališče)*"
                                required
                                :rules="address_rules"
                                filled
                            ></v-text-field>

                            <!-- kraj -->
                            <v-text-field
                                v-model="town"
                                label="Kraj*"
                                required
                                :rules="town_rules"
                                filled
                            ></v-text-field>

                            <!-- poštna številka -->
                            <v-text-field
                                v-model="postal_code"
                                label="Poštna številka*"
                                required
                                :rules="postal_code_rules"
                                type="number"
                                filled
                            ></v-text-field>

                            <!-- emšo
                            <v-text-field
                                v-model="emso"
                                label="EMŠO*"
                                required
                                :rules="emso_rules"
                                type="number"
                                filled
                            ></v-text-field>-->

                            <!-- tip osebe (podjetje / fizična oseba) -->
                                <v-select
                            :items="entities"
                            label="Izberi tip osebe*"
                            item-text="name"
                            item-value="id"
                            v-model="entity"
                            :rules="entity_rules"
                            filled
                            ></v-select>
                            
                            <!-- davčna številka -->
                            <v-text-field
                                v-model="tax_number"
                                label="Davčna številka*"
                                required
                                :rules="tax_number_rules"
                                v-if="requireTaxNumber"
                                filled
                            ></v-text-field>

                            <div class="div__divider__15"></div>
                            <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ noteText}}</span>
                            <div class="div__divider__15"></div>
                            <v-textarea
                            outlined
                            name="input-7-4"
                            label="Vnesite željeno besedilo (opombe, zahteva za spremembo osebnih podatkov ipd.)"
                            v-model="note"
                            counter
                            required
                            ></v-textarea>
                            <div class="div__divider__15"></div>

                            <!-- tip uporabnika -->
                            <v-select
                            :items="userTypes"
                            label="Tip dovolilnice"
                            item-text="name"
                            item-value="id"
                            v-model="user_type"
                            :rules="user_type_rules"
                            ></v-select>

                            <!-- telefonska številka -->
                            <v-text-field
                                v-model="phone_number"
                                label="Telefonska številka"
                                required
                                :rules="telephone_number_rules"
                                type="number"
                            ></v-text-field>

                            <!-- registrska številka -->
                            <v-text-field
                                v-model="registration_plate_number_1"
                                label="Registrska številka vozila"
                                :rules="registration_plate_number_rules"
                                required
                            ></v-text-field>

                            <!-- vozilo v osebni lasti ali lasti podjetja -->
                            <v-select
                            :items="vehicleOwnershipTypes"
                            label="Lastništvo vozila"
                            item-text="name"
                            item-value="id"
                            v-model="vehicle_ownership"
                            :rules="vehicle_ownership_rules"
                            ></v-select>

                            <v-divider></v-divider>
                            <br>
                            <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite fotokopijo vašega prometnega dovoljenja. V primeru da je vozilo v lasti podjetja, morate priložiti potrdilo od podjetja.</p>
                            <!-- dokumenti -->
                            <VueFileAgent
                            :uploadUrl="'https://www.mocky.io/v2/5d4fb20b3000005c111099e3'"
                            :uploadHeaders="{}"
                            :multiple="true"
                            :deletable="true"
                            :editable="true"
                            :meta="true"
                            :accept="'image/*,.pdf'"
                            :maxSize="'14MB'"
                            :maxFiles="8"
                            :helpText="'Dodaj datoteke...'"
                            :errorText="{
                                type: 'Dovoljen tip datotek - jpg, png, pdf do 3MB',
                                size: 'Dodali ste datoteko, ki presega največjo dovoljeno velikost 3MB!',
                            }"
                            :thumbnailSize="120"
                            :theme="'list'"
                            v-model="files_data"
                            ></VueFileAgent>

                            <br><div style="min-height:5px"></div>
                        </v-card-text>

                        <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-information-outline</v-icon>
                                <v-toolbar-title class="body-2">Podatki o vlogi</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="toggleCardContent('card2')">
                                    <v-icon>{{ card2 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider v-show="card2"></v-divider>
                            <v-card-text v-show="card2">      
                                <v-container grid-list-xl text-x-center align-center>
                                    <!-- številka vloge -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Številka vloge</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="applicationId"
                                                label="Številka vloge"
                                                readonly
                                                append-icon="mdi-numeric"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout> 

                                    <!-- status vloge -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Status vloge</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            :items="applicationStatuses"
                                            label="Status vloge"
                                            item-text="name"
                                            item-value="id"
                                            v-model="application_status"
                                            :rules="application_status_rules"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>

                                    <!-- datum oddaje -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Datum oddaje vloge</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="submission_date"
                                                label="Datum oddaje vloge"
                                                readonly
                                                append-icon="mdi-calendar-month"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>

                                    <!-- čas oddaje -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Čas oddaje vloge</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-text-field
                                                v-model="submission_time"
                                                label="Čas oddaje vloge"
                                                readonly
                                                append-icon="mdi-clock-outline"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    
                                    <!-- plačilo -->
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Status plačila</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                             <!--<v-text-field
                                                v-model="payment"
                                                label="Status plačila"
                                                readonly
                                                append-icon="mdi-currency-eur"
                                            ></v-text-field>-->
                                            <v-select
                                            :items="paymentStatuses"
                                            label="Status plačila"
                                            item-text="name"
                                            item-value="id"
                                            v-model="payment_status"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    
                                </v-container>
                            </v-card-text>

                            <span v-if="associatedPermitId != null">
                                <v-divider></v-divider>
                                <v-app-bar
                                    flat
                                    color="grey lighten-4"
                                    dense
                                    class="elevation-0"
                                >   
                                    <v-icon left>mdi-information-outline</v-icon>
                                    <v-toolbar-title class="body-2">Podatki o dovolilnici</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="toggleCardContent('card3')">
                                        <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                    </v-btn>
                                </v-app-bar>
                                <v-divider v-show="card3"></v-divider>
                                <v-card-text v-show="card3">
                                    <v-container grid-list-xl text-x-center align-center>
                                        <!-- številka dovolilnice -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Številka dovolilnice</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="associatedPermitId"
                                                    label="Številka dovolilnice"
                                                    readonly
                                                    append-icon="mdi-numeric"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout> 

                                        <!-- status dovolilnice -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Status dovolilnice</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="associatedPermitStatusName"
                                                    label="Status dovolilnice"
                                                    readonly
                                                    append-icon="mdi-state-machine"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <!-- veljavnost dovolilnice -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Veljavnost dovolilnice (od - do)</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="permitRestrictionStartEnd"
                                                    label="Veljavnost dovolilnice"
                                                    readonly
                                                    append-icon="mdi-calendar-month"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout> 

                                        <!-- datum oddaje -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Datum izdaje dovolilnice</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="associatedPermitCreateDate"
                                                    label="Datum izdaje dovolilnice"
                                                    readonly
                                                    append-icon="mdi-calendar-month"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                        <!-- čas oddaje -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-center>
                                                <v-subheader class="pt-7 pl-0"><strong>Čas izdaje dovolilnice</strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-center>
                                                <v-text-field
                                                    v-model="associatedPermitCreateTime"
                                                    label="Čas izdaje dovolilnice"
                                                    readonly
                                                    append-icon="mdi-clock-outline"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>

                                         <!-- btns -->
                                        <v-layout row justify-center v-bind="columnBinding">
                                            <v-flex xs12 md4 align-content-left v-if="$vuetify.breakpoint.lgAndUp">
                                                <v-subheader><strong></strong></v-subheader>
                                            </v-flex>
                                            <v-flex xs12 md8 align-content-left>
                                                <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" color="#546E7A" dark @click="editPermit()"><v-icon left>mdi-arrow-right</v-icon>Prikaži dovolilnico</v-btn>
                                                &nbsp;&nbsp;
                                                <v-btn class="btn_rfid" small :block="$vuetify.breakpoint.xsOnly" color="#546E7A" dark @click="goToUserPage()"><v-icon left>mdi-account</v-icon>Profil uporabnika</v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-card-text>
                            </span>
                        <v-divider></v-divider>

                        <!-- obveščanje -->
                        <v-app-bar
                        flat
                        color="grey lighten-4"
                        dense
                        class="elevation-0"
                        >   
                            <v-icon left>mdi-email-outline</v-icon>
                            <v-toolbar-title class="body-2">Obveščanje</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="card4 = !card4">
                                <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-app-bar>
                        <v-divider v-show="card4"></v-divider>
                        <v-card-text v-show="card4">
                            <!-- komponenta za pošiljanje mailov, obveščanje o uspešno oddani vlogi in zavrnitvi dovolilnice -->        
                            <div>
                                <reject-application-notification :application="application"></reject-application-notification>
                            </div>                           
                        </v-card-text>


                        <v-divider></v-divider>
                        <v-card-text v-if="$vuetify.breakpoint.mdAndDown">
                            <!--<v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="loading ||associatedPermitId != null">Shrani vlogo</v-btn>-->
                            <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;"><v-icon left>mdi-arrow-right</v-icon>Shrani vlogo</v-btn>
                            <!--<v-btn v-if="associatedPermitId == null" @click="clearForm()" color="error" block outlined style="margin-bottom:10px;">Prekliči</v-btn>-->
                            <v-btn @click="clearForm()" color="warning" block outlined style="margin-bottom:10px;"><v-icon left>mdi-refresh</v-icon>Prekliči spremembe</v-btn>
                            <v-btn @click="deleteApplication()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Izbriši vlogo</v-btn>
                            <v-btn v-if="associatedPermitId == null" @click="createPermit()" color="info" block outlined style="margin-bottom:10px;" :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>Ustvari dovolilnico</v-btn>
                            <!--<v-btn v-else @click="editPermit()" color="info" block outlined style="margin-bottom:10px;" :disabled="loading">Odpri dovolilnico</v-btn>-->
                        </v-card-text>
                        <v-card-actions v-else>
                            <!--<v-btn @click="submitForm()"  color="success" text :disabled="loading || associatedPermitId != null">Shrani vlogo</v-btn>-->
                            <v-btn @click="submitForm()"  color="success" text><v-icon left>mdi-arrow-right</v-icon>Shrani vlogo</v-btn>
                            <!--<v-btn v-if="associatedPermitId == null" @click="clearForm()" color="error" text>Prekliči</v-btn>-->
                            <v-btn  @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči spremembe</v-btn>
                            <v-btn @click="deleteApplication()" color="error" text><v-icon left>mdi-close</v-icon>Izbriši vlogo</v-btn>
                            <v-btn v-if="associatedPermitId == null" @click="createPermit()" color="info" text :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>Ustvari dovolilnico</v-btn>
                            <!--<v-btn v-else @click="editPermit()" color="info" text :disabled="loading">Odpri dovolilnico</v-btn>-->
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>
        <br>
        <application-files-data-table :applicationUuid="uuid" :boxedLayout="true"></application-files-data-table>

        <v-dialog v-model="dialog" persistent max-width="490">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-icon text>mdi-delete</v-icon>
                    {{ dialog_text }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click.native="dialogCancel()">Prekliči</v-btn>
                    <v-btn text :loading="loading" color="success" @click.native="dialogConfirm()">Nadaljuj</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import imgCardPromenada from '@/assets/card_promenada.jpg'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const ApplicationFilesDataTable = () => import('@/components/ApplicationFilesDataTable.vue')
const RejectApplicationNotification = () => import('@/components/admin/RejectApplicationNotification.vue')
import { applicationMediaTypeColor } from '@/helpers/utilities.js'

export default {
    name: 'edit-promenada-e-application',
    props: [
        'uuid', 'applicationId', 'application'
    ],

    components: {
        FormValidationMessages,
        FormSubmitMessages,
        ApplicationFilesDataTable,
        RejectApplicationNotification
    },

    data: () => ({
        timeout1: null,
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        valid: true, 
        application_media_type: null,
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        temporary_residence_address: null,
        telephone_number: null,
        tax_number: null,
        subject_type: null,
        phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        vehicle_ownership: null,
        noteText: "Vnesite opombe ipd.",
        note: null,
        delivery_type: null,
        disclaimer: false,
        disclaimer_text: 'S potrditvijo vloge izjavljam, da so vsi podatki, ki sem jih navedel v vlogi, resnični, točni in popolni in da za svojo izjavo prevzemam vso materialno in kazensko odgovornost. V skladu z določbo 45. člena Odloka organ odvzame pravico do dovolilnice če se izkaže, da podatki v vlogi niso resnični oziroma, da upravičenec več ne izpolnjuje pogojev za dovolilnico.',
        enable_submission: true,
        files_data: [],
        application_status: null,
        submission_date: null,
        submission_time: null,
        payment_status: null,

        userTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],
        applicationStatuses: [],
        paymentStatuses: [],

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        application_status_rules: [v => !!v || 'Polje status vloge je obvezno'],
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        disclaimer_rules: [v => !!v || 'Strinjati se morate s pogoji'],
        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],
        loading:false,
        
        //podatki o dovolilnici ... če jo že ima :)
        associatedPermitId: null,
        associatedPermitUuid: null,
        associatedPermitStatusName: null,
        associatedPermitCreateDate: null,
        associatedPermitCreateTime: null,
        associatedPermitRestrictionStartDate: null,
        associatedPermitRestrictionEndDate: null,

        dialog:false,
        dialog_text: null,

        
    }),
    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        imgPromenada() {
            return imgCardPromenada
        },

        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        temporaryResidence() {
            if(this.residence_type == "045189aa-656c-4284-8e8d-b91611e896bf") {
                return true
            }

            return false
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        permitRestrictionStartEnd() {
            if(this.associatedPermitRestrictionStartDate != '' && this.associatedPermitRestrictionStartDate != null && this.associatedPermitRestrictionEndDate != '' && this.associatedPermitRestrictionEndDate != null) {
                return this.associatedPermitRestrictionStartDate + " - " + this.associatedPermitRestrictionEndDate
            }

            if((this.associatedPermitRestrictionStartDate == null || this.associatedPermitRestrictionStartDate == '') && (this.associatedPermitRestrictionEndDate != '' && this.associatedPermitRestrictionEndDate != null)) {
                return "/ - " + this.associatedPermitRestrictionEndDate            }

            return "/"
        },

        rejectNotificationApplicationData() {
            return {
                applicationId: this.application
            }
        }
        
    },

    methods: {
        toggleCardContent(content) {
            if(content === 'card1') {
                this.card1 = !this.card1
            }

            if(content === 'card2') {
                this.card2 = !this.card2
            }

            if(content === 'card3') {
                this.card3 = !this.card3
            }
        },

        getResources() {
            var vm = this;
            if(vm.$store.getters.userTypes !== null) {
                vm.$store.getters.userTypes.forEach(element => {
                    if(element.hasOwnProperty('type')) {
                        if(element.type == 3 || element.type == 4 || element.type == 5 || element.type == 6 || element.type == 7) {
                            vm.userTypes.push(element)
                        }
                    }
                })
            }
            
            vm.residenceTypes = vm.$store.getters.residenceTypes
            vm.entities = vm.$store.getters.entities
            vm.vehicleOwnershipTypes = vm.$store.getters.vehicleOwnershipTypes
            vm.applicationStatuses = vm.$store.getters.applicationStatuses
            vm.paymentStatuses = vm.$store.getters.paymentStatuses

        },

        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            let vm = this
            if(this.$refs.form.validate()) {
                /*let one  = 1;
                if(1 == one){*/
                vm.$store.commit('SET_LOADER_TEXT', 'Posodobitev vloge je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = new FormData();
                payload.append('application_uuid', vm.uuid)
                payload.append('user_type', vm.user_type)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('phone_number', vm.phone_number)
                payload.append('emso', vm.emso)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('vehicle_ownership', vm.vehicle_ownership)
                payload.append('application_status', vm.application_status)
                payload.append('payment_status', vm.payment_status)
                payload.append('note', vm.note)

                let c = 1;
                vm.files_data.map(file => {
                    payload.append('file_' + c, file.file)
                    c++
                });
                
                //window.console.log(payload)
                vm.loading = true
                axios({
                    method: 'POST',
                    url: API_ENDPOINT + 'v1/admin/applications/promenada/'  + vm.uuid + '/update',
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //window.console.log(response)
                    vm.$store.getters.userTypes.forEach(element => {
                        if(element.hasOwnProperty('id')) {
                            if(element.id == vm.user_type) {
                                vm.application.user_type.uuid = element.id
                                vm.application.user_type.name = element.name
                                vm.application.user_type.type = element.type
                            }
                        }
                    })
                    vm.files_data = []

                    setTimeout(() => {
                        vm.backendMessages.push('Vloga je bila uspešno posodobljena.')
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                        vm.$store.commit('SET_SNACKBAR_TEXT', "Vloga je bila uspešno posodobljena")
                        vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                        vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                        vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    }, 700)

                    /*vm.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                    })*/
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri posodobitvi vloge je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 500,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .then(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                    window.console.log("finally! :)");
                })
            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                window.console.log("Validation ERROR");
            }
        },

        clearForm() {
            this.$refs.form.resetValidation();
            let vm = this

            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o vlogi je v teku...')
            this.$store.commit('SET_LOADER', true)
            
            EventBus.$emit('GET_APPLICATION_DATA', vm.uuid);
            EventBus.$on('REFRESH_APPLICATION_DATA', function(data) {
                vm.$store.commit('SET_LOADER', false)
                if(data.success != true) {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri osveževanju podatkov vloge je prišlo do napake. Prosimo poskusite ponovno.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                } else {
                    vm.user_type = vm.application.user_type.uuid;
                    vm.name = vm.application.name
                    vm.address = vm.application.address
                    vm.town = vm.application.town
                    vm.postal_code = vm.application.postal_code
                    vm.phone_number = vm.application.phone_number
                    vm.emso = vm.application.emso
                    vm.tax_number = vm.application.tax_number
                    vm.entity = vm.application.entity_type.uuid,
                    vm.registration_plate_number_1 = vm.application.registration_plate_number_1
                    vm.vehicle_ownership = vm.application.vehicle_ownership
                    vm.permit_type = vm.application.permit_type.uuid
                    vm.files_data = [];
                    vm.application_status = (vm.application.application_status != null) ? vm.application.application_status.uuid : null
                    vm.note = vm.application.note
                    vm.submission_date = vm.application.created_at_date
                    vm.submission_time = vm.application.created_at_time
                    vm.associatedPermitId = vm.application.permit_id
                    vm.associatedPermitUuid = vm.application.permit_uuid
                    vm.associatedPermitStatusName = (vm.application.permit != null && vm.application.permit.status != null) ? vm.application.permit.status.name : null
                    vm.associatedPermitCreateDate = (vm.application.permit != null) ? vm.application.permit.created_at_date : null
                    vm.associatedPermitCreateTime = (vm.application.permit != null) ? vm.application.permit.created_at_time : null
                    vm.associatedPermitRestrictionStartDate = (vm.application.permit != null) ? vm.application.permit.restriction_start_date: null
                    vm.associatedPermitRestrictionEndDate = (vm.application.permit != null) ? vm.application.permit.restriction_end_date: null
                    vm.payment_status = (vm.application.payment_status != null) ? vm.application.payment_status.uuid : null

                    this.backendValidationMessages = []
                    this.backendMessages = []
                    this.backendErrorMessages = []
                }
            })
            setTimeout(() => {
                vm.user_type = vm.application.user_type.uuid;
                vm.name = vm.application.name
                vm.address = vm.application.address
                vm.town = vm.application.town
                vm.postal_code = vm.application.postal_code
                vm.phone_number = vm.application.phone_number
                vm.emso = vm.application.emso
                vm.tax_number = vm.application.tax_number
                vm.entity = vm.application.entity_type.uuid,
                vm.registration_plate_number_1 = vm.application.registration_plate_number_1
                vm.vehicle_ownership = vm.application.vehicle_ownership
                vm.permit_type = vm.application.permit_type.uuid
                vm.files_data = [];
                vm.application_status = (vm.application.application_status != null) ? vm.application.application_status.uuid : null
                vm.submission_date = vm.application.created_at_date
                vm.submission_time = vm.application.created_at_time
                vm.associatedPermitId = vm.application.permit_id
                vm.associatedPermitUuid = vm.application.permit_uuid
                vm.associatedPermitStatusName = (vm.application.permit != null && vm.application.permit.status != null) ? vm.application.permit.status.name : null
                vm.associatedPermitCreateDate = (vm.application.permit != null) ? vm.application.permit.created_at_date : null
                vm.associatedPermitCreateTime = (vm.application.permit != null) ? vm.application.permit.created_at_time : null
                vm.associatedPermitRestrictionStartDate = (vm.application.permit != null) ? vm.application.permit.restriction_start_date: null
                    vm.associatedPermitRestrictionEndDate = (vm.application.permit != null) ? vm.application.permit.restriction_end_date: null
                    vm.payment = (vm.application.payment == 1) ? "Plačano" : "Ni plačano"

                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []
            }, 700)

            /*setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 500,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)*/
        },

        createPermit() {
            var vm = this
            if(vm.associatedPermitUuid == null && vm.associatedPermitId == null) {
                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []
                let vm = this
                if(this.$refs.form.validate()) {
                    vm.$store.commit('SET_LOADER_TEXT', 'Generiranje dovolilnice je v teku...')
                    vm.$store.commit('SET_LOADER', true)

                    let payload = new FormData();
                    payload.append('application_uuid', vm.uuid)
                    payload.append('application_id', vm.applicationId)
                    payload.append('user_type', vm.user_type)
                    payload.append('name', vm.name)
                    payload.append('address', vm.address)
                    payload.append('town', vm.town)
                    payload.append('postal_code', vm.postal_code)
                    payload.append('phone_number', vm.phone_number)
                    payload.append('emso', vm.emso)
                    payload.append('tax_number', vm.tax_number)
                    payload.append('entity', vm.entity)
                    payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                    payload.append('vehicle_ownership', vm.vehicle_ownership)
                    payload.append('application_status', vm.application_status)
            

                    /*
                    let c = 1;
                    vm.files_data.map(file => {
                        payload.append('file_' + c, file.file)
                        c++
                    });
                    */
                    
                    //window.console.log(payload)
                    vm.loading = true
                    vm.$store.dispatch('CREATE_PROMENADA_PERMIT_FROM_APPLICATION', payload)
                    .then(response => {
                        window.console.log(response)
                        vm.associatedPermitId = response.data.data.permit_id;
                        vm.associatedPermitUuid = response.data.data.permit_uuid;
                        vm.associatedPermitStatusName = response.data.data.permit_status
                        vm.associatedPermitCreateDate = response.data.data.created_at_date
                        vm.associatedPermitCreateTime = response.data.data.created_at_time
                        

                        //vm.files_data = []

                        setTimeout(() => {
                            vm.backendMessages.push('Generiranje dovolilnice je uspelo.')
                            vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                            vm.$store.commit('SET_SNACKBAR_TEXT', "Generiranje dovolilnice je uspelo")
                            vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                            vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                            vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                        }, 700)

                        /*vm.$vuetify.goTo('#form_card', {
                        duration: 500,
                        offset: 0,
                        easing: 'easeInOutCubic'
                        })*/
                    })
                    .catch(error => {
                        window.console.log(error)
                        window.console.log(error.response)

                        if(error.response.status === 422) {
                            window.console.log(error.response.data.errors)

                            for (const property in error.response.data.errors) {
                                //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                                var propertyData = error.response.data.errors[property];
                                //window.console.log(propertyData);
                                propertyData.forEach(message => {
                                    vm.backendValidationMessages.push(message);
                                })

                                vm.$vuetify.goTo('#form_card', {
                                    duration: 500,
                                    offset: 0,
                                    easing: 'easeInOutCubic'
                                })
                            }
                        } else {
                            vm.backendErrorMessages.push('Ups... pri generiranju dovolilnice je prišlo do napake. Poskusite ponovno.')
                            vm.$vuetify.goTo('#form_card', {
                                duration: 500,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }

                    })
                    .finally(() => {
                        setTimeout(() => {
                            vm.$store.commit('SET_LOADER', false)
                            vm.$store.commit('SET_LOADER_TEXT', '')
                            vm.clearForm();
                        }, 600)
                        vm.loading = false
                        window.console.log("finally! :)");
                    })
                } 
                else {
                    this.$vuetify.goTo('#form_card', {
                        duration: 500,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                    window.console.log("Validation ERROR");
                }
            }
            else {
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                vm.$store.commit('SET_SNACKBAR_TEXT', "Dovolilnica za vlogo številka #" + vm.applicationId + " že obstaja.")
                vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            }
        },

        editPermit() {
            var vm = this;
            this.$router.push({
                   name: 'admin_permits_edit',
                   params: {
                       id: vm.associatedPermitUuid
                   }
               })
        },

        deleteApplication() {
            this.dialog_text = "Ste prepričani, da želite odstraniti vlogo s številko #" + this.applicationId + "?"
            this.dialog = true;
        },

        dialogConfirm() {
            var vm = this
            vm.dialog = false
            this.$store.commit('SET_LOADER_TEXT', 'Brisanje vloge v teku...')
            this.$store.commit('SET_LOADER', true)

            vm.$store.dispatch('DELETE_APPLICATION', vm.uuid)
            .then(response => {
                window.console.log(response)
                this.$store.commit('SET_LOADER', false)
                this.$router.push({
                    name: 'admin_applications'
                })

                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Vloga številka #" +vm.applicationId + " je bila uspešno odstranjena.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                }, 900)
            })
            .catch(error => {
                window.console.error("### ERROR DELETE_APPLICATION ###")
                window.console.error(error)

                this.$store.commit('SET_LOADER', false)
                vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri brisanju vloge številka #" +vm.applicationId + " je prišlo do napake.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
            })
        },

        dialogCancel() {
            this.dialog = false
        },

        goToUserPage() {
            this.$router.push({
                   name: 'admin_users_edit',
                   params: {
                       id:  this.application.user.uuid
                   }
               })
        }
    },

    created() {
        this.application_media_type = applicationMediaTypeColor('digital')
        //this.getResources();
    },

    mounted() {
        this.getResources();
        var vm = this
        this.timeout1 = setTimeout(() => {
            vm.user_type = vm.application.user_type.uuid;
            vm.name = vm.application.name
            vm.address = vm.application.address
            vm.town = vm.application.town
            vm.postal_code = vm.application.postal_code
            vm.phone_number = vm.application.phone_number
            vm.emso = vm.application.emso
            vm.tax_number = vm.application.tax_number
            vm.entity = vm.application.entity_type.uuid,
            vm.registration_plate_number_1 = vm.application.registration_plate_number_1
            vm.vehicle_ownership = vm.application.vehicle_ownership
            vm.permit_type = vm.application.permit_type.uuid
            vm.files_data = [];
            vm.application_status = (vm.application.application_status != null) ? vm.application.application_status.uuid : null
            vm.note = vm.application.note
            vm.submission_date = vm.application.created_at_date
            vm.submission_time = vm.application.created_at_time
            vm.associatedPermitId = vm.application.permit_id
            vm.associatedPermitUuid = vm.application.permit_uuid
            vm.associatedPermitStatusName = (vm.application.permit != null && vm.application.permit.status != null) ? vm.application.permit.status.name : null
            vm.associatedPermitCreateDate = (vm.application.permit != null) ? vm.application.permit.created_at_date : null
            vm.associatedPermitCreateTime = (vm.application.permit != null) ? vm.application.permit.created_at_time : null
            vm.associatedPermitRestrictionStartDate = (vm.application.permit != null) ? vm.application.permit.restriction_start_date: null
            vm.associatedPermitRestrictionEndDate = (vm.application.permit != null) ? vm.application.permit.restriction_end_date: null
            vm.payment_status = (vm.application.payment_status != null) ? vm.application.payment_status.uuid : null
        }, 400)
    },

    beforeDestroy() {
        EventBus.$off('REFRESH_APPLICATION_DATA')
        clearTimeout(this.timeout1);
    }
}

</script>

<style scoped>
    .v-card__text {
        color: rgba(0,0,0,0.90) !important;
    }
</style>